<template>
	<v-layout column fill-height>
		<v-flex shrink>
			<v-layout justify-end px-2 row>
				<w-btn v-show="userRoles.isAdmin" icon="add" @click="dialog = true">{{ $t('customer.users.actions.invite') }}</w-btn>
				<w-dialog v-model="dialog" :max-width="$vuetify.breakpoint.lgAndUp ? '50vw' : '80vw'" :title="$t('customer.users.actions.invite')">
					<v-layout row wrap>
						<v-flex px-1 xs12>
							<v-text-field
								v-model="newUser.email"
								:rules="[rules.required, rules.email]"
								color="primary"
								:label="$t('email')"
								maxlength="191"
								prepend-icon="email"
							/>
						</v-flex>
						<v-flex px-1 xs6>
							<v-text-field
								v-model="newUser.firstname"
								:disabled="!canEditName"
								:rules="[rules.requiredName]"
								color="primary"
								:label="$t('first_name')"
								maxlength="191"
							/>
						</v-flex>
						<v-flex px-1 xs6>
							<v-text-field
								v-model="newUser.lastname"
								:disabled="!canEditName"
								:rules="[rules.requiredName]"
								color="primary"
								:label="$t('last_name')"
								maxlength="191"
							/>
						</v-flex>
						<v-flex px-1 xs12>
							<v-select
								v-model="newUser.role_id"
								color="primary"
								:disabled="authUser.id === newUser.id"
								:items="roles"
								item-text="name"
								item-value="id"
								:label="$t('customer.users.role')"
								:loading="rolesLoading"
							/>
						</v-flex>
						<v-flex px-1 xs12>
							<v-select
								v-model="newUser.job_id"
								:rules="[rules.required]"
								color="primary"
								:items="jobs"
								item-text="name"
								item-value="id"
								:label="$t('customer.users.job')"
								:loading="jobsLoading"
							/>
						</v-flex>
						<v-flex v-if="hasThemeModule" px-1 xs12>
							<v-select
								v-model="newUser.themes"
								color="primary"
								:items="themes"
								item-text="title"
								item-value="id"
								:label="$t('customer.users.available_themes')"
								multiple
							>
								<template v-slot:selection="{ item }">
									<v-chip small :color="item.color">
										<v-icon color="white" small class="mr-2">{{ item.is_shared ? 'fas fa-share-alt' : 'fas fa-key' }}</v-icon>
										<span class="white--text">{{ item.title }}</span>
									</v-chip>
								</template>
							</v-select>
						</v-flex>
						<v-flex px-1 xs12>
							<v-select
								v-model="newUser.apps"
								color="primary"
								:items="apps"
								item-text="title"
								item-value="id"
								:label="$t('customer.users.available_apps')"
								multiple
							/>
						</v-flex>
						<v-flex px-1 xs12>
							<UserRevocationDate v-if="userRoles.isAdmin" v-model="newUser.revocation_date"></UserRevocationDate>
						</v-flex>
						<v-checkbox v-model="newUser.send_mail" color="primary" :label="$t('customers.actions.send_invitation_mail')" hide-details />
					</v-layout>
					<template v-slot:actions>
						<v-layout align-center justify-end row>
							<w-btn :disabled="!inviteUserIsValid" icon="save" flat @click="inviteUser">{{ $t('actions.save') }}</w-btn>
						</v-layout>
					</template>
				</w-dialog>
			</v-layout>
		</v-flex>
		<v-flex scroll-y>
			<v-expansion-panel v-model="panel">
				<v-expansion-panel-content v-for="(user, index) in sortedUsers" :key="index">
					<template v-slot:header>
						<v-layout align-center justify-space-between row>
							<v-flex :class="index === panel ? 'primary--text' : ''" shrink text-truncate v-text="user.username" />
							<v-flex v-if="index === panel" shrink>
								<w-btn v-if="userRoles.isAdmin && $vuetify.breakpoint.mdAndUp && !user.invited_at" flat icon="add" @click.stop="sendMail(user)">{{
									$t('customers.actions.invite_user')
								}}</w-btn>
								<w-btn
									v-if="userRoles.isAdmin && $vuetify.breakpoint.mdAndUp && !user.did_connect && user.invited_at"
									flat
									icon="redo"
									@click.stop="sendMail(user)"
									>{{ $t('customers.actions.reinvite_user') }}</w-btn
								>
								<w-btn
									v-if="userRoles.isAdmin && $vuetify.breakpoint.mdAndUp && user.invited_at && user.did_connect"
									flat
									icon="loop"
									@click.stop="sendMail(user)"
									>{{ $t('customers.actions.reinitialize_password') }}</w-btn
								>
								<w-btn-save v-if="userRoles.isAdmin && $vuetify.breakpoint.mdAndUp" :disabled="!isUpdateFormValid[user.id]" flat @click.stop="updateUser(user.id)">
									{{ $t('actions.save') }}
								</w-btn-save>
								<v-btn v-else v-show="userRoles.isAdmin" :disabled="!isUpdateFormValid[user.id]" color="primary" flat icon @click.stop="updateUser(user.id)">
									<v-icon>save</v-icon>
								</v-btn>
								<v-btn v-show="canDeleteUser" color="primary" flat icon @click.stop="deleteUser(user)">
									<v-icon>delete</v-icon>
								</v-btn>
							</v-flex>
						</v-layout>
					</template>
					<v-card>
						<v-card-text>
							<v-form v-model="isUpdateFormValid[user.id]">
								<v-layout row wrap>
									<v-flex px-1 xs12 lg4 xl3>
										<w-text-input v-model="userForms[user.id].firstname" required :disabled="!userRoles.isAdmin || !isAccountantAdmin" :label="$t('first_name')" />
									</v-flex>
									<v-flex px-1 xs12 lg4 xl3>
										<w-text-input v-model="userForms[user.id].lastname" required :disabled="!userRoles.isAdmin || !isAccountantAdmin" :label="$t('last_name')" />
									</v-flex>
									<v-flex px-1 xs12 lg4 xl3>
										<w-email-input v-model="userForms[user.id].email" disabled :label="$t('email')" />
									</v-flex>
									<v-flex px-1 xs12 sm6 lg4 xl3>
										<w-phone-input
											v-model="userForms[user.id].mobile_number"
											:disabled="!userRoles.isAdmin"
											:label="$t('customer.users.mobile_number')"
											prepend-icon="phone"
										/>
									</v-flex>
									<v-flex v-if="panel === index" px-1 xs12 sm6 lg4 xl3>
										<w-date-picker
											v-model="userForms[user.id].birthday"
											:birthday="true"
											:disabled="!userRoles.isAdmin"
											:label="$t('customer.users.birthday')"
										></w-date-picker>
									</v-flex>
									<v-flex px-1 xs12 lg4 xl3>
										<w-text-input
											v-model="userForms[user.id].birth_city"
											:disabled="!userRoles.isAdmin"
											:label="$t('customer.users.birth_city')"
											maxlength="191"
											prepend-icon="place"
										/>
									</v-flex>
									<v-flex px-1 xs12 lg8 xl5>
										<w-text-input v-model="userForms[user.id].address" :disabled="!userRoles.isAdmin" :label="$t('address')" maxlength="191" />
									</v-flex>
									<v-flex px-1 xs12 sm9 md5 xl3>
										<w-text-input v-model="userForms[user.id].city" :disabled="!userRoles.isAdmin" :label="$t('city')" maxlength="191" />
									</v-flex>
									<v-flex px-1 xs12 sm3 md2 xl1>
										<w-text-input v-model="userForms[user.id].post_code" :disabled="!userRoles.isAdmin" :label="$t('post_code')" maxlength="5" />
									</v-flex>
									<v-flex px-1 xs12 md5 xl3>
										<w-text-input v-model="userForms[user.id].country" :disabled="!userRoles.isAdmin" :label="$t('country')" maxlength="191" />
									</v-flex>
									<v-flex px-1 xs12 md6>
										<w-select
											v-model="userForms[user.id].role_id"
											:disabled="authUser.id === user.id || !userRoles.isAdmin"
											:items="roles"
											item-text="name"
											item-value="id"
											:label="$t('customer.users.role')"
											:loading="rolesLoading"
											required
										/>
									</v-flex>
									<v-flex px-1 xs12 md6>
										<w-select
											v-model="userForms[user.id].job_id"
											:disabled="!userRoles.isAdmin"
											:items="jobs"
											item-text="name"
											item-value="id"
											:label="$t('customer.users.job')"
											:loading="jobsLoading"
											required
										/>
									</v-flex>
									<v-flex v-if="hasThemeModule" px-1 xs12 lg6>
										<w-select
											v-model="userForms[user.id].themes"
											:disabled="!userRoles.isAdmin"
											:items="themes"
											item-text="title"
											item-value="id"
											:label="$t('customer.users.available_themes')"
											multiple
										>
											<template v-slot:selection="{ item }">
												<v-chip small :color="item.color">
													<v-icon color="white" small class="mr-2">{{ item.is_shared ? 'fas fa-share-alt' : 'fas fa-key' }}</v-icon>
													<span class="white--text">{{ item.title }}</span>
												</v-chip>
											</template>
										</w-select>
									</v-flex>
									<v-flex px-1 xs12 lg6>
										<w-select
											v-model="userForms[user.id].apps"
											:disabled="!userRoles.isAdmin"
											:items="apps"
											item-text="title"
											item-value="id"
											:label="$t('customer.users.available_apps')"
											multiple
										/>
									</v-flex>
									<UserRevocationDate v-if="authUser.id !== user.id && userRoles.isAdmin" v-model="userForms[user.id].revocation_date"></UserRevocationDate>
								</v-layout>
							</v-form>
						</v-card-text>
					</v-card>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-flex>
	</v-layout>
</template>

<script>
import Vue from 'vue'

import { mapState } from 'vuex'
import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/VendorSettings/VendorsSettingsModuleGuard'

export default {
	name: 'CustomerDetailsUsers',
	components: {
		UserRevocationDate: () => ({
			component: import('@/components/CustomersManager/CustomerDetails/Users/UserRevocationDate')
		})
	},
	mixins: [CustomersManagerModuleGuard],
	props: {
		company: {
			required: true,
			type: Object
		}
	},
	data: function () {
		return {
			apps: [],
			dialog: false,
			isUpdateFormValid: {},
			jobs: [],
			jobsLoading: false,
			newUser: { send_mail: true },
			panel: null,
			roles: [],
			rolesLoading: false,
			themes: [],
			users: [],
			canEditName: false,
			userAlreadyExist: false,
			rules: {
				required: function (val) {
					let result = true
					if (!val || !val.toString().trim()) {
						result = this.$t('required')
					}
					return result
				}.bind(this),
				requiredName: function (val) {
					let result = true
					if ((!val || !val.toString().trim()) && !this.userAlreadyExist) {
						result = this.$t('required')
					}
					return result
				}.bind(this),
				email: function (val) {
					let result = true
					if (!val || !val.trim() || !this.isEmailValid(val)) {
						result = this.$t('invalid_email')
					}
					return result
				}.bind(this)
			},
			userForms: {}
		}
	},
	computed: {
		...mapState({
			authUser: state => state.user.data,
			isAccountant: state => state.auth.isAccountant,
			userRoles: state => state.company.userRoles,
			isAccountantAdmin: state => state.accountingFirm.selected.isAccountantAdmin,
			accountingFirm: state => state.accountingFirm.selected
		}),
		canDeleteUser: function () {
			return this.userRoles.isAdmin
		},
		firstDayOfTheWeek: function () {
			return this.$firstDayOfTheWeek()
		},
		hasThemeModule: function () {
			return this.appService.hasModule('themes')
		},
		inviteUserIsValid: function () {
			let result = true
			if (
				!this.newUser.email ||
				!this.newUser.email.trim() ||
				!this.isEmailValid(this.newUser.email) ||
				(!this.userAlreadyExist && !this.newUser.firstname) ||
				(!this.userAlreadyExist && !this.newUser.firstname.trim()) ||
				(!this.userAlreadyExist && !this.newUser.lastname) ||
				(!this.userAlreadyExist && !this.newUser.lastname.trim()) ||
				!this.newUser.role_id ||
				!this.newUser.job_id
			) {
				result = false
			}
			return result
		},
		sortedUsers: function () {
			let sortedUsers = this.users.slice()
			sortedUsers.sort((a, b) => {
				if(a.firstname == null) {
					a.firstname = ''
				}
				if(b.firstname == null) {
					b.firstname = ''
				}
				return a.firstname.localeCompare(b.firstname)
			})
			return sortedUsers
		},
	},
	watch: {
		'company.id': {
			handler: function (val) {
				this.panel = null
				if (val) {
					this.getApps()
					this.getThemes()
					this.getUsers()
				}
			},
			immediate: true
		},
		dialog: {
			handler: async function (val) {
				if (! val) {
					this.newUser = { send_mail: true }
					return
				}

				this.newUser.send_mail = true

				if (this.jobs.length === 0) {
					this.loadJobs();
				}

				if (this.roles.length === 0) {
					await this.loadRoles();
				}

				if (!this.newUser.role_id && this.users.length === 0) {
					this.newUser.role_id = this.roles.find(role => role.key === 'admin').id
				}
			}
		},
		panel: {
			handler: function (newVal, oldVal) {
				if (oldVal || !Number.isInteger(newVal)) {
					return;
				}

				if (this.roles.length === 0) {
					this.loadRoles();
				}
				if (this.jobs.length === 0) {
					this.loadJobs();
				}
			},
			immediate: true
		},
		'newUser.email': {
			handler: function (val) {
				if (this.isEmailValid(val)) {
					this.getUserInfoByEmail(val)
				} else {
					if (this.userAlreadyExist) {
						Vue.set(this.newUser, 'firstname', '')
						Vue.set(this.newUser, 'lastname', '')
					}
					this.canEditName = false
					this.userAlreadyExist = false
				}
			}
		},
		users() {
			this.mapUserForms()
		}
	},
	methods: {
		loadRoles: async function () {
			try {
				this.rolesLoading = true
				this.roles = await this.service.getRoles(this.accountingFirm.id)
			} finally {
				this.rolesLoading = false
			}
		},
		loadJobs: async function () {
			try {
				this.jobsLoading = true
				this.jobs = await this.service.getJobs()
			} finally {
				this.jobsLoading = false
			}
		},
		isEmailValid (email) {
			return email
				?.trim()
				?.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
		},
		getUserInfoByEmail (email) {
			this.service
				.getUserByEmail(email, { show_error: false })
				.then(userInfo => {
					if (userInfo) {
						this.userAlreadyExist = true
						this.canEditName = false
						Vue.set(this.newUser, 'firstname', userInfo.firstname)
						Vue.set(this.newUser, 'lastname', userInfo.lastname)
					} else {
						if (this.userAlreadyExist) {
							Vue.set(this.newUser, 'firstname', '')
							Vue.set(this.newUser, 'lastname', '')
						}
						this.userAlreadyExist = false
						this.canEditName = true
					}
				})
		},
		deleteUser: function (user) {
			this.$dialog
				.warning({
					text: this.$t('customer.users.actions.are_you_sure', { company: this.company.company, username: user.username }),
					title: this.$t('customer.users.actions.delete'),
					actions: {
						false: this.$t('actions.no'),
						true: this.$t('actions.yes')
					}
				})
				.then(res => {
					if (res) {
						const accountingFirmId = this.accountingFirm.id
						this.service.deleteUser(accountingFirmId, this.company.id, user.id).then(() => {
							this.users = this.users.filter(item => user.id != item.id)
							this.panel = null
							this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('user.actions.deleted'))
						})
					}
				})
		},
		getApps: function () {
			const accountingFirmId = this.accountingFirm.id
			this.service.getApps(accountingFirmId, this.company.id).then(res => {
				this.apps = res
			})
		},
		getThemes: function () {
			if (this.isAccountant) {
				const accountingFirmId = this.accountingFirm.id
				this.service
					.getThemesForAccountant(accountingFirmId, this.company.id)
					.then(themes => themes.filter(theme => theme.is_shared))
					.then(themes => {
						this.themes = themes
					})
			} else {
				this.service.getThemesForCompanyUser(this.company.id).then(res => {
					this.themes = res
				})
			}
		},
		getUsers: function () {
			this.service.getUsersOfCompany(this.company.id).then(users => {
				this.users.splice(0, this.users.length)
				users.forEach(user => {
					Vue.set(this.isUpdateFormValid, `${user.id}`, true)
					this.users.push(user)
				})
			})
		},
		inviteUser: function () {
			const accountingFirmId = this.accountingFirm.id
			this.service.addUser(accountingFirmId, this.company.id, this.newUser).then(user => {
				Vue.set(this.isUpdateFormValid, `${user.id}`, true)
				user.role = this.roles.find(role => role.id === user.role_id)
				this.users.push(user)
				this.dialog = false
				this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('user.actions.added'))
			})
		},
		mapUserForms: function () {
			this.userForms = this.users.reduce((acc, user) => {
				let formattedBirthday = user.birthday ? new Date(user.birthday) : null;
				formattedBirthday = isNaN(formattedBirthday?.getDate()) ? null : formattedBirthday.toISOString().slice(0, 10);
				acc[user.id] = { ...user, birthday: formattedBirthday };

				return acc;
			}, {});
		},
		/**
		 * @param {number} userId
		 *
		 * @return {void}
		 */
		updateUser: function (userId) {
			const user = this.userForms[userId]
			if (!this.isAccountantAdmin) {
				delete user.firstname
				delete user.lastname
			}
			const accountingFirmId = this.accountingFirm.id
			const vendorId = this.company.id

			this.service
				.updateUser(accountingFirmId, vendorId, user.id, user)
				.then(userUpdated => {
					const userIndex = this.users.findIndex(usr => usr.id === userUpdated.id)
					if (userIndex !== -1) {
						Vue.set(this.users, userIndex, userUpdated)
						this.panel = this.sortedUsers.findIndex(usr => usr.id === userUpdated.id)
					}
				})
				.then(() => {
					this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('user.actions.updated'))
				})
		},
		sendMail: function (user) {
			let text = ''
			let title = ''

			if (!user.invited_at) {
				text = this.$t('customers.actions.are_you_sure_send_mail_invitation', { email: user.email })
				title = this.$t('customers.actions.are_you_sure_title_mail_invitation', { name: user.username })
			} else if (!user.did_connect && user.invited_at) {
				text = this.$t('customers.actions.are_you_sure_send_mail_reinvitation', { email: user.email })
				title = this.$t('customers.actions.are_you_sure_title_mail_reinvitation', { name: user.username })
			} else if (user.did_connect && user.invited_at) {
				text = this.$t('customers.actions.are_you_sure_send_mail_reset_password', { email: user.email })
				title = this.$t('customers.actions.are_you_sure_title_mail_reset_password', { name: user.username })
			}
			this.showSendMailDialog(text, title, user)
		},
		showSendMailDialog: function (text, title, user) {
			this.$dialog
				.warning({
					text: text,
					title: title,
					actions: {
						false: this.$t('actions.no'),
						true: this.$t('actions.yes')
					}
				})
				.then(res => {
					let result
					if (res) {
						let action
						if (!user.invited_at || (!user.did_connect && user.invited_at)) {
							const accountingFirmId = this.accountingFirm.id
							action = this.service.sendMail(accountingFirmId, this.company.id, user.id).then(() => {
								this.users = this.users.map(item => {
									if (item.id == user.id) {
										item.invited_at = Date.now()
									}
									return item
								})
							})
						} else if (user.did_connect && user.invited_at) {
							action = this.service.resetPasswordSendMail(user.email, [window.location.origin])
						}
						if (action) {
							result = action
								.then(() => {
									this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('actions.mail_sent'))
								})
								.catch(error => {
									this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, error)
								})
						}
					}
					return result
				})
		}
	}
}
</script>
